import React from 'react'
import { ContactUs } from '../components/ContactUs'
import { InlineWidget } from 'react-calendly'
import Layout from '../components/layout'

export default function ContactUsPage() {
    return (
        <Layout>
            <div id='contactUs_page'>
                <div id='contactUs_banner'>
                    <div>
                        <h1>Contact Us!</h1>
                        <h3>Send a message or schedule a free consultation.<br/>We'd love to hear from you!</h3>
                    </div>
                </div>
                <div id='contactUs_container'>
                    <ContactUs />
                </div>
                <div className='consult_component'>
                    <InlineWidget
                        styles={{
                            height: '60rem',
                            width: '100%'
                        }}
                        pageSettings={{
                            backgroundColor: '696969',
                            hideEventTypeDetails: false,
                            hideLandingPageDetails: false,
                            primaryColor: 'FFA500',
                            textColor: 'ffffff'
                        }}
                        url='https://calendly.com/chattanoogamarketsolutions'></InlineWidget>
                </div>
            <div id='contactUs_information'>
                    <div>
                        <img alt='location.png' src='https://i.imgur.com/eb2I38j.png'></img>
                        <p>651 E 4th St<br />Chattanooga, TN 37405</p>
                    </div>
                    <div>
                        <img alt='phone.png' src='https://i.imgur.com/nHCZgMF.png'></img>
                        <p>423-834-4308</p>
                    </div>
                    <div>
                        <img alt='email.png' src='https://i.imgur.com/roraxI7.png'></img>
                        <p>info@chattmarketsolutions.com</p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
import React from 'react'
import isEmail from 'validator/es/lib/isEmail'

export class ContactUs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companyName: '',
            name: '',
            email: '',
            position: '',
            message: '',
            loading: false,
            errors: {
                companyName: false,
                name: false,
                email: false,
                message: false,
            },
        }
    }

    resetForm = () => {
        this.setState({
            companyName: '',
            name: '',
            email: '',
            position: '', 
            message: '',
            loading: false,
            errors: {
                companyName: false,
                name: false,
                email: false,
                message: false,
            }
        })
    }
    onCompanyNameChange = (e) => {
        this.setState({ companyName: e.target.value })
    }
    onNameChange = (e) => {
        this.setState({ name: e.target.value })
    }
    onEmailChange = (e) => {
        this.setState({ email: e.target.value })
        this.setState({ 
            errors: {
                email: !isEmail(e.target.value)
            } 
        })
    }
    onPositionChange = (e) => {
        this.setState({ position: e.target.value })
    }
    onMessageChange = (e) => {
        this.setState({ message: e.target.value })
    }
    setIsLoading = (boolean) => {
        this.setState({ loading: boolean })
    }
    onSubmit = (e) => {
        e.preventDefault()
        this.setIsLoading(true)
    
        const { companyName, name, email, message, position } = this.state
        const website = 'chris@chattmarketsolutions.com'
        const req = { companyName, name, email, message, website, position }

        fetch('https://demo-site-server.herokuapp.com/send', {
            method: "POST",
            body: JSON.stringify(req),
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
        })
            .then((response) => (response.json()))
            .then((response)=> {
                if (response.status === 'success') {
                    alert("Message Sent.") 
                    this.resetForm()
                    this.setIsLoading(false)
                } else if (response.status === 'fail') {
                    alert("Message failed to send.")
                    this.setIsLoading(false)
                }
            })
    }

    render() {
        return (
            <div id='contactUs_element'>
                <form onSubmit={this.onSubmit} method='POST'>
                    <div id='contactUs_form'>
                        <div>
                            <label>
                                <input placeholder='Company Name' value={this.state.companyName} onChange={this.onCompanyNameChange}></input>
                            </label>
                        </div>
                        {
                            this.state.errors.companyName === true && <p>Please enter a valid company</p>
                        }
                        <div id='contactUs--emailAndPosition'>
                            <label>
                                <input placeholder='Email' value={this.state.email} onChange={this.onEmailChange}></input>
                            </label>
                            <label>
                                <input placeholder='Position' value={this.state.position} onChange={this.onPositionChange}></input>
                            </label>
                            {
                                this.state.errors.email === true && <p>Please enter a valid email</p>
                            }
                        </div>
                        <div>
                            <label>
                                <input placeholder='Name First/Last' value={this.state.name} onChange={this.onNameChange}></input>
                            </label>
                        </div>
                        <div>
                            <label>
                                <textarea placeholder='Type your message here...' id='contactUs_element--message' value={this.state.message} onChange={this.onMessageChange}></textarea>
                            </label>
                        </div>
                        {
                            this.state.loading === true && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        }
                        <button id='contactUs_element--button'>Send</button>
                    </div>
                </form>
            </div>
        )
    }
}